import "bootstrap/dist/css/bootstrap.min.css";
import Carosoul from "./components/Carosoul";
import NavbarC from "./components/NavbarC";
// import Services from "./components/Services";
// import About from "./components/About";
import About2 from "./components/About2";
import AboutDetail from "./components/AboutDetail";
import Footer from "./components/Footer";
import Testimonial2 from "./components/Testimonial2";

function App() {
  return (
    <div className="App">
      <NavbarC />
      <h1
        className="text-center heading mt-4 pt-5"
        id="home"
        data-aos="fade-right"
      >
        Cheque Bounce Lawyer In Jaipur
      </h1>
      <Carosoul />

      <About2 />
      <AboutDetail />

      <Testimonial2 />
      <Footer />
    </div>
  );
}

export default App;
