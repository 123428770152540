import React from "react";
import {
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsTelephone,
} from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import { HiMailOpen } from "react-icons/hi";
const Footer = () => {
  return (
    <div className="footerSection bg-dark text-light pt-4 pb-1" id="footer">
      <div className="container" data-aos="fade-up">
        <div className="aboutUs mb-4">
          <h3 className="mb-4 ">Address</h3>
          <div className="adderssWrapper d-flex flex-column gap-2">
            <div className="wrapper d-flex gap-2">
              <div>
                <ImLocation />
              </div>
              <div className="info">
                <div>Address</div>
                <div>
                  35-A 1st Floor Tirupati Vihar, Goliyawas, Mansarovar,
                  Jaipur,Rajsathan ,302012
                </div>
              </div>
            </div>
            <div className="wrapper d-flex gap-2">
              <div>
                <BsTelephone />
              </div>
              <div className="info">
                <div>Call Us</div>
                <div>099291 38420</div>
              </div>
            </div>
            <div className="wrapper d-flex gap-2">
              <div>
                <HiMailOpen />
              </div>
              <div className="info">
                <div>Email</div>
                <div>support@checkbouncelawyerjaipur.com</div>
              </div>
            </div>
          </div>
        </div>
        <div className="quickLinks">
          <h3 className="mb-4">Quick Links</h3>
          <ul className="d-flex flex-column gap-2">
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#testimonial">Testimonial</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </div>
        <div className="social">
          <h3 className="mb-4">Social</h3>
          <div className="socialMedia">
            <a href="https://facebook.com" target="_blank" rel="noreferrer">
              <BsFacebook />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noreferrer">
              <BsInstagram />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <BsTwitter />
            </a>
          </div>
          <div
            style={{
              fontSize: "1.2rem",

              marginTop: "1.5rem",
            }}
          >
            Our Advocates
          </div>
          <div>Advocate Sunil Sharma</div>
        </div>
      </div>
      <p className="text-center">Copyright &copy; All Rights Reserved | 2022</p>
    </div>
  );
};

export default Footer;
