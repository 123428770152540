import React, { useState, useRef } from "react";
// import emailjs from "@emailjs/browser";
import { FcOk } from "react-icons/fc";
import Success from "./Success";

const About = () => {
  const [modalShow, setModalShow] = useState(false);
  const [formState, setFormstate] = useState({});

  const submitForm = (e) => {
    setFormstate({ ...formState, [e.target.name]: e.target.value });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const config = {
      Username: "hkukreti4@gmail.com",
      Password: "6ABAD6210F111B74869AFD96C9BDDEA1ACA1",
      Host: "smtp.elasticemail.com",
      Port: 2525,
      To: "hkukreti4@gmail.com",
      From: formState.email,
      Subject: "this is a message from " + formState.name,
      Body: formState.message,
    };
    if (window.Email) {
      window.Email.send(config).then((message) => alert(message));
    }
  };

  return (
    <div className="aboutSection py-5" id="about">
      <div className="container">
        <div className="aboutDetails p-2" data-aos="fade-left">
          <div className="heading  mb-4">
            <strong>
              Lawyer You Can Trust,<br></br>Advice You Can Rely On
            </strong>
          </div>
          <div className="aboutPoints">
            <FcOk /> 100% Confidentiality
          </div>
          <div className="aboutPoints">
            <FcOk /> Guaranteed Satisfaction
          </div>
          <div className="aboutPoints">
            <FcOk /> Approachable and <br /> &nbsp; &nbsp; Solution-focused
            attorneys
          </div>
        </div>

        {/* /// form /////////////////////////// */}
        <div className="formDetails p-2" id="contact" data-aos="fade-right">
          <h2 className="mb-4">Contact Me</h2>
          <form
            action="/"
            className="d-flex flex-column gap-3"
            onSubmit={submitHandler}
          >
            <input
              type="text"
              placeholder="Enter Your Name"
              value={formState.name || ""}
              required
              onChange={submitForm}
              name="name"
            />
            <input
              type="email"
              value={formState.email || ""}
              placeholder="Enter Your Email"
              required
              name="email"
              onChange={submitForm}
            />
            <input
              type="text"
              value={formState.mobile || ""}
              placeholder="Enter Your Mobile"
              required
              onChange={submitForm}
              pattern="[0-9]{10}"
              maxLength="10"
              minLength="10"
              title="Required only Number"
              name="mobile"
            />
            <textarea
              value={formState.message || ""}
              id=""
              cols="30"
              rows="5"
              placeholder="Message"
              required
              name="message"
              onChange={submitForm}
            ></textarea>
            <button type="submit" value="Send" className="btn btn-primary">
              Send Message
            </button>
          </form>
        </div>
      </div>
      <Success show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default About;
