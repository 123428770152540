import React from "react";

const AboutDetail = () => {
  return (
    <div className="aboutDetailsSection bg-dark text-light py-4">
      <div className="container " data-aos="fade-down">
        <h1 className="text-center my-2">Cheque Bounce Lawyer In Jaipur</h1>

        <p className="mt-3">
          Cheque bounce is a criminal offense U/s 138 of Negotiable instrument
          act 1881. <br /> <br /> Cheque bounce case is also known as dishonor
          of cheque, there are various reasons for cheque bounce case. A cheque
          is a bill of exchange, it is payable on demand drawn by the applicant.
          The person one who gives the cheque is a drawer and on who receives it
          is a drawee. The cheque is valid for three (3) months from the date of
          the cheque.
          <div className="mt-4">
            <img
              src="../img1.png"
              alt="chequeBounceimage"
              style={{ width: "100%", height: "50%" }}
            />
          </div>
          <br /> <br />
          <strong style={{ fontSize: "1.5rem" }}>
            Here are few points from the Best Cheque Bounce Lawyers in Jaipur.
          </strong>
          <br /> <br />
          The first procedure has to be followed, when the cheque has been
          returned, from the bank, and then formally send a legal notice to the
          drawer within 30 days from the cheque has been returned.
          <br />
          <br /> - In the legal notice, it is to be mentioned that the amount
          has to be paid within the time limit of 15 days from the date of legal
          notice received by the drawer or appropriate legal action will be
          taken under N.I. act.
          <br />
          <br /> - After sending the notice the drawer must preserve the
          delivery of proof.
          <br /> - The drawee can file a complaint within the local jurisdiction
          of the court.
          <br /> <br />
          For registering a complaint the drawee must have all the documents
          <br /> 1. Cheque and cheque returned memo issued by the bank
          <br /> 2. Written complaint <br /> 3. Legal notice photocopy/Xerox
          <br /> 4. Letter of the oath/sworn affidavit <br />
          <br /> If the payment is not made by the drawer/payee then the
          complainant is to file a criminal case under 138 of the act. Against
          the drawer within the 30 days from the date of expiry of 15 days
          specified in the notice. After filing the complaint in the court, the
          court should find prima facie in the complaint, then a summons will be
          issued to the accused. After summons has been serviced to the accused
          and he abstains from appearing in the court then the court may issue a
          bailable warrant. <br /> - After the appearance of the accused/ drawer
          then he must furnish a bail bond to ensure his appearance during
          trial. <br /> - The complainant may present his evidence in the court
          and produce all the original documents in support of complaint.
          Advocate Sunil Sharma .
          <br /> - Accused also given an opportunity to produce his documents to
          give his evidence.
          <br /> - The final stage of the proceedings is that of the arguments
          by both sides of the parties and after hearing the arguments by the
          court, the court will pronounce a judgment.
        </p>
      </div>
    </div>
  );
};

export default AboutDetail;
