import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
const Carosoul = () => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="heroSection ">
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        className="carosoul"
        interval="2000"
      >
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://images.pexels.com/photos/6863254/pexels-photo-6863254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="First slide"
            style={{ filter: "brightness(50%)" }}
          />
          <Carousel.Caption>
            <h3>Lawyers are the foot soldiers of our constitution.</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://media.istockphoto.com/photos/judge-office-picture-id1361278196?b=1&k=20&m=1361278196&s=170667a&w=0&h=txOrCej9KpACyqgNZqQV2P5qjXhu0t-dXRJ7O5rplXg="
            alt="Second slide"
            style={{ filter: "brightness(50%)" }}
          />

          <Carousel.Caption>
            <h3>If there were no bad people there would be no good Lawyer </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://images.pexels.com/photos/6862457/pexels-photo-6862457.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="third slide"
            style={{ filter: "brightness(50%)" }}
          />

          <Carousel.Caption>
            <h3>
              A good Layer knows the act. A great lawyer knows the justice.{" "}
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Carosoul;
