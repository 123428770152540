import Carousel from "react-bootstrap/Carousel";
import { FaQuoteRight, FaQuoteLeft } from "react-icons/fa";

const comments = [
  {
    image:
      "https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvczkzLXBhLTQ3MTNfMi5wbmc.png?s=X5JUjNRe0-0CSqZ40V0zzkYt3sQqNKboHvKR1KmEz1g",
    name: "Vivek",
    comment:
      "finally, after a long search, I found a lawyer who is advising me like a friend and try to understand the problem and give the right way and make me understand my case in a legal manner so that I can understand the pros and cons of my case. And I can save my time. Best advocate in Jaipur.",
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQj-SIX1EZG07fdgjTsLZK0wK9p4eWK6IY5jw&usqp=CAU",
    name: "Seema",
    comment:
      "meeting was successful, the lawyer helped me in my legal issues.",
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREol4Aa6gMv_NypZQpUfEkiGzRC--ZKtI7Lw&usqp=CAU",
    name: "Hariom",
    comment:
      "The lawyer was an expert in my legal issue. The lawyer gave me the right guidance. The lawyer helped me in making the proper choice going ahead.",
  },
];
function Testimonial2() {
  return (
    <div className="testimonialSection  py-4" id="testimonial">
      <div className="container">
        <Carousel interval="2000">
          {comments.map((e, i) => (
            <Carousel.Item key={i}>
              <div className="boxWrapper text-center">
                <img src={e.image} alt="" />
                <h3>{e.name}</h3>
                <p>
                  <span className="left">
                    <FaQuoteLeft />
                  </span>
                  {e.comment}
                  <span className="right">
                    <FaQuoteRight />
                  </span>
                </p>
              </div>
            </Carousel.Item>
          ))}

          {/* <Carousel.Item>
            <div className="boxWrapper text-center">
              <img
                src="https://spng.pngfind.com/pngs/s/173-1737582_1000-x-972-71-4-business-man-with.png"
                alt=""
              />
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="boxWrapper text-center">
              <img
                src="https://spng.pngfind.com/pngs/s/173-1737582_1000-x-972-71-4-business-man-with.png"
                alt=""
              />
              <h3>First slide label</h3>
              <p>
                Nulla vitae elit libero, a pharetra augue mollis interdum .
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi laborum inventore voluptatibus quisquam possimus
                doloribus, nihil placeat dolor ipsum quo distinctio. Fuga itaque
                debitis nisi rem accusamus. Iste, harum error?
              </p>
            </div>
          </Carousel.Item> */}
        </Carousel>
      </div>
    </div>
  );
}
export default Testimonial2;
